import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useEhrFhirClient } from './ehr-fhir-client-context';
import ErrorMessage from '../ErrorMessage';
import { useEnvironment } from '../hooks/use-environment';
import LoadingMessage from '../LoadingMessage';

interface ZusTokenContextProps {
  token: string | null;
}

const defaultContextValue: ZusTokenContextProps = {
  token: null,
};

export const ZusTokenContext = createContext<ZusTokenContextProps>(defaultContextValue);

export const ZusTokenProvider = ({ children }: { children: React.ReactNode }) => {
  const fhirClient = useEhrFhirClient();
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const environment = useEnvironment();

  useEffect(() => {
    const getZusToken = async () => {
      const tokenResponse = await fetch(`${environment.ehrHooksURL}/token/athena`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: fhirClient.client?.state.tokenResponse?.id_token,
        }),
      });
      if (tokenResponse.status !== 200) {
        setError(new Error('Could not get Zus token'));
      } else {
        const tokenJson = await tokenResponse.json();
        setToken(tokenJson.access_token);
      }
    };

    if (fhirClient.client?.state.tokenResponse?.id_token && !token) {
      // To test locally using SMART launcher (https://launch.smarthealthit.org/) you can hardcode the Zus token here and comment out the rest of the function
      // setToken(
      //   ""
      // );
      getZusToken().catch((e) => {
        setError(e);
      });
    }
  }, [fhirClient.client?.state.tokenResponse?.id_token, environment.ehrHooksURL, token]);

  const contextValue = useMemo(() => ({ token }), [token]);

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  if (!token) {
    return <LoadingMessage />;
  }

  return <ZusTokenContext.Provider value={contextValue}>{children}</ZusTokenContext.Provider>;
};

export const useZusToken = () => useContext(ZusTokenContext);
