import { Telemetry } from '@ctw/shared/context/telemetry';
import { useEffect, useState } from 'react';
import { useEhrFhirClient } from '../context/ehr-fhir-client-context';

type UniqueIdentifier = {
  system: string;
  value: string;
};

export type Patient = {
  ehrPatient: fhir4.Patient | undefined;
  uniqueIdentifier: UniqueIdentifier | undefined;
  error: Error | undefined;
};

export const usePatient = (): Patient => {
  const [ehrPatient, setEhrPatient] = useState<fhir4.Patient | undefined>(undefined);
  const [uniqueIdentifier, setUniqueIdentifier] = useState<UniqueIdentifier | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  const fhirClient = useEhrFhirClient();

  // get patient from EHR
  useEffect(() => {
    const getEHRPatient = async () => {
      const patient = await fhirClient.client?.patient.read();
      if (!patient) {
        throw new Error('Could not get patient from EHR');
      }
      setEhrPatient(patient);

      const uniqueId =
        (patient as fhir4.Patient).identifier?.find(
          (i) => i.value === fhirClient.client?.patient.id,
        ) ?? (patient as fhir4.Patient).identifier?.[0];

      if (!uniqueId) {
        throw new Error('Unable to find unique identifier for patient');
      }

      const { system, value } = uniqueId;

      if (!system || !value) {
        throw new Error("Unique identifier doesn't have system and value");
      }
      setUniqueIdentifier({ system, value });
    };
    if (fhirClient.client?.patient.id && !ehrPatient && !error) {
      getEHRPatient().catch((e) => {
        Telemetry.logger.error(
          'Failed to get patient from EHR',
          {
            ehrPatientId: fhirClient.client?.patient.id,
          },
          e,
        );
        setError(e);
      });
    }
  }, [fhirClient.client?.patient, ehrPatient, error]);

  return {
    ehrPatient,
    uniqueIdentifier,
    error,
  };
};
