export type Environment = {
  env: string;
  ehr: string | undefined;
  fqsURL: string;
  ehrHooksURL: string;
};

export const useEnvironment = (): Environment => {
  let env = 'sandbox';
  if (window.location.host === 'smart.dev.zusapi.com') {
    env = 'dev';
  } else if (window.location.host === 'smart.sandbox.zusapi.com') {
    env = 'sandbox';
  } else if (window.location.host === 'smart.zusapi.com') {
    env = 'production';
  }

  const envSubdomain = env === 'production' ? '' : `${env}.`;

  return {
    env,
    fqsURL: `https://fqs.${envSubdomain}zusapi.com`,
    ehrHooksURL: `https://ehr-hooks.${envSubdomain}zusapi.com`,
    ehr: new URLSearchParams(window.location.search).get('ehr') ?? undefined,
  };
};
