import '@ctw/shared/styles/main.scss';
import { oauth2 } from 'fhirclient-pkce';
import { useEffect } from 'react';
import LoadingMessage from './LoadingMessage';

const ehrScopes: Record<string, string> = {
  ecw: 'launch openid profile user/Patient.read',
  athena: 'launch openid profile email fhirUser user/Patient.read',
};

const Launch = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const ehr = queryParams.get('ehr');
  const scope = ehr ? ehrScopes[ehr] : null;
  const clientId = queryParams.get('clientId');

  useEffect(() => {
    if (clientId && scope) {
      const redirectUri = `${window.location.origin}/zap${
        ehr ? `?ehr=${encodeURIComponent(ehr)}` : ''
      }`;

      oauth2
        .authorize({
          clientId,
          scope,
          redirectUri,
          pkceMode: 'required',
        })
        .catch(() => {});
    }
  }, [clientId, ehr, scope]);

  if (!ehr) {
    return <div>ehr is required</div>;
  }

  if (!clientId) {
    return <div>clientId is required</div>;
  }

  if (!scope) {
    return <div>Invalid EHR selected</div>;
  }

  return <LoadingMessage />;
};

export default Launch;
