import '@ctw/shared/styles/main.scss';
import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { CTWProvider } from '@ctw/shared/context/ctw-provider';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { Telemetry } from '@ctw/shared/context/telemetry';
import { useEffect } from 'react';
import { EhrFhirClientProvider, useEhrFhirClient } from './context/ehr-fhir-client-context';
import { FeatureFlagProvider } from './context/feature-flag-context';
import { useZusToken, ZusTokenProvider } from './context/zus-token-context';
import ErrorMessage from './ErrorMessage';
import { useEnvironment } from './hooks/use-environment';
import { usePatient } from './hooks/use-patient';
import LoadingMessage from './LoadingMessage';
import { TelemetryProvider } from '@ctw/shared/context/telemetry/telemetry-provider';
import { getPageEnv } from '@ctw/shared/utils/get-page-env';
import { claimsBuilderId } from '@ctw/shared/utils/auth';

const { VITE_GIT_SHA } = import.meta.env;
const EHR_TYPE_ATHENA = 'athena';

const ZAP = () => {
  const environment = useEnvironment();
  const zusToken = useZusToken();
  const patient = usePatient();
  const fhirClient = useEhrFhirClient();

  useEffect(() => {
    Telemetry.logger.info('Mounting smart-on-fhir zap');
    return () => Telemetry.logger.info('Unmounting smart-on-fhir zap');
  }, []);

  // For Athena we want to refresh the chart after saving a resource
  // so that the user can see the effect of their action. Though we do
  // not want to refresh the chart after saving a Basic resource since
  // those are not reflected in Athena.
  const onResourceSave =
    environment.ehr === EHR_TYPE_ATHENA ?
      async (resource: fhir4.Resource) => {
        if (resource.resourceType !== 'Basic') {
          window.parent.postMessage(
            {
              type: 'embeddedAppAPIMessage',
              method: 'notifyPatientDataChange',
              methodVersion: '1.0.0',
            },
            environment.env === 'production' ?
              'https://athenanet.athenahealth.com'
            : 'https://preview.athenahealth.com',
          );
        }
      }
    : undefined;

  // For Athena we need to pass the department ID in the request headers
  // so that we can use it when saving resources.
  let resourceSaveHeaders: Record<string, string> | undefined;
  if (environment.ehr === EHR_TYPE_ATHENA) {
    const department = fhirClient.client?.state.tokenResponse?.ah_department;
    if (!department) {
      throw Error('Missing department ID in Athena token response');
    }
    resourceSaveHeaders = { ah_department: department };
  }

  if (patient.error) {
    return <ErrorMessage message={patient.error.message} />;
  }

  if (zusToken.token && patient.uniqueIdentifier) {
    return (
      <CTWProvider
        env={environment.env}
        authToken={zusToken.token}
        ehr={environment.ehr}
        allowSmallBreakpointCCDAViewer
        onResourceSave={(resource) => {
          if (onResourceSave) {
            void onResourceSave(resource);
          }
        }}
        resourceSaveHeaders={resourceSaveHeaders}
      >
        <TelemetryProvider
          builderId={claimsBuilderId(zusToken.token)}
          serviceEnv={getPageEnv()}
          ehr={environment.ehr ?? 'unknown'}
          service="smart-on-fhir"
          serviceVersion={VITE_GIT_SHA}
        >
          <PatientProvider
            systemURL={patient.uniqueIdentifier.system}
            patientID={patient.uniqueIdentifier.value}
          >
            <ZusAggregatedProfile hideTitle />
          </PatientProvider>
        </TelemetryProvider>
      </CTWProvider>
    );
  }

  return <LoadingMessage />;
};

const ZAPWrapper = () => (
  <EhrFhirClientProvider>
    <ZusTokenProvider>
      <FeatureFlagProvider>
        <ZAP />
      </FeatureFlagProvider>
    </ZusTokenProvider>
  </EhrFhirClientProvider>
);

export default ZAPWrapper;
