import '@ctw/shared/styles/preflight.css'; // Must come first to take lower specificity.
import './index.css';
import { ErrorBoundary } from '@ctw/shared/content/common-error-boundary';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(container!).render(
  <React.StrictMode>
    <ErrorBoundary title="Zus SMART on FHIR">
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);
