import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import '@ctw/shared/styles/main.scss';
import { tw } from '@ctw/shared/utils/tailwind';

export type LoadingMessageProps = {
  message?: string;
};

const LoadingMessage = ({ message = 'Loading Zus Aggregated Profile' }: LoadingMessageProps) => (
  <div className={tw`flex h-full items-center justify-center`}>
    <LoadingSpinner className={tw`text-xl`} message={message} />
  </div>
);

export default LoadingMessage;
