import {
  getUnleashContext,
  getUnleashProxyForEnv,
} from '@ctw/shared/context/feature-flag-provider';
import { FlagProvider, UnleashClient, useUnleashContext } from '@unleash/proxy-client-react';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useZusToken } from './zus-token-context';
import { useEnvironment } from '../hooks/use-environment';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';

type FeatureFlags = Record<string, boolean>;

export const FeatureFlagContext = createContext<FeatureFlags>({});

export type FeatureFlagProviderProps = {
  children: ReactNode;
};

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const environment = useEnvironment();

  const unleashClient = useMemo(
    () =>
      new UnleashClient({
        ...getUnleashProxyForEnv(environment.env),
        refreshInterval: 15, // (in seconds)
        appName: 'smart-on-fhir',
        context: {},
      }),
    [environment.env],
  );

  return (
    <FlagProvider unleashClient={unleashClient} startClient={false}>
      <FeatureFlagProviderComponent unleashClient={unleashClient}>
        {children}
      </FeatureFlagProviderComponent>
    </FlagProvider>
  );
}

type FeatureFlagProviderComponentProps = {
  children: ReactNode;
  unleashClient: UnleashClient;
};

// Component responsible for fetching the auth token and updating the Unleash context once the auth token has been fetched.
// This needs to be a child of `FlagProvider` in order to have access to the Unleash context.
const FeatureFlagProviderComponent = ({
  unleashClient,
  children,
}: FeatureFlagProviderComponentProps) => {
  const updateContext = useUnleashContext();
  const zusToken = useZusToken();
  const [unleashClientStarted, setUnleashClientStarted] = useState(false);
  const [unleashClientReady, setUnleashClientReady] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();

  // configure the unleash client
  useEffect(() => {
    if (zusToken.token && !unleashClientStarted) {
      void (async function run() {
        unleashClient.on('ready', () => {
          setUnleashClientReady(true);
          setFeatureFlags(
            Object.fromEntries(
              unleashClient.getAllToggles().map((toggle) => [toggle.name, toggle.enabled]),
            ),
          );
        });
        await updateContext(getUnleashContext(zusToken.token as string));
        setUnleashClientStarted(true);
        await unleashClient.start();
      })();
    }
  }, [
    zusToken,
    unleashClient,
    unleashClientStarted,
    unleashClientReady,
    featureFlags,
    updateContext,
  ]);

  if (featureFlags === undefined) {
    return <LoadingSpinner message="Loading..." />;
  }

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};
