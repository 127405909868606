import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Launch from './Launch';
import ZAP from './ZAP';

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/launch" element={<Launch />} />
        <Route path="/zap" element={<ZAP />} />
      </Routes>
    </BrowserRouter>
  );
}
